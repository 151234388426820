import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Container = styled.div`
  @media (min-width: 768px) {
  }
`

const List = styled.div`
  font-size: 0.875em;
  padding-top: 1em;
  display: flex;
  align-items: center;
  justify-content: start;
`
const Content = styled.div`
  padding-left: 0.5em;
  flex: 1;
`
const Image = styled(Link)`
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`
const Title = styled(Link)`
  text-decoration: none;
  line-height: 1.3em;
  color: black;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`
const Date = styled.div`
  /* font-size: 0; */
  padding-top: 0.2em;
  color: gray;
`

function RecentPostList({ nodes }) {
  return (
    <Container>
      {nodes.map(({ node }) => {
        return (
          <List key={node.frontmatter.title}>
            <Image to={node.fields.slug}>
              <Img fixed={node.frontmatter.topImage.childImageSharp.fixed} />
            </Image>
            <Content>
              <Title to={node.fields.slug}>{node.frontmatter.title}</Title>
              <Date>{node.frontmatter.created_at}</Date>
            </Content>
          </List>
        )
      })}
    </Container>
  )
}

export default RecentPostList
