import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout_sidebar"
import styled from "styled-components"
import Img from "gatsby-image"
import PostCardCategoryList from "../components/post/PostCardCategoryList"
import PostCardDate from "../components/post/PostCardDate"
import SEO from "../components/layout/seo"

const Article = styled.article`
  margin: 2em 1em;
  h2 {
    font-weight: 700;
    font-size: 1.5em;
    padding: 1em 0;
  }

  h3 {
    font-weight: 700;
    font-size: 1.3em;
  }

  h4 {
    font-weight: 700;
    font-size: 1.1em;
  }

  p {
    margin: 1em 0;
    line-height: 1.8em;
  }
`
const Title = styled.h1`
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 1em;
`

const Content = styled.div`
  margin-top: 1em;
  .gatsby-resp-image-figcaption {
    text-align: center;
    color: gray;
    font-size: 0.875em;
    margin-top: 1em;
  }
`

export default function Post({ data }) {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        image={frontmatter.topImage.childImageSharp.fluid.src}
        article={true}
      />
      <Img fluid={frontmatter.topImage.childImageSharp.fluid} />
      <Article>
        <Title>{frontmatter.title}</Title>
        <PostCardCategoryList categories={frontmatter.categories} />
        <PostCardDate
          created_at={frontmatter.created_at}
          updated_at={frontmatter.updated_at}
        />
        <Content dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
      </Article>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        categories
        created_at
        updated_at
        description
        topImage {
          id
          childImageSharp {
            fluid(maxWidth: 680) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
