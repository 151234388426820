import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import RecentPostList from "./RecentPostList"

const Container = styled.div`
  border: 1px solid #e0e0e0;
  padding: 1em;
  margin: 1em 2em;
  @media (min-width: 768px) {
    margin: 0;
  }
`
const Content = styled.div`
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
`

const RecentPost = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { order: DESC, fields: frontmatter___created_at }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              created_at
              updated_at
              categories
              topImage {
                relativePath
                childImageSharp {
                  id
                  fixed(width: 80) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const nodes = data.allMarkdownRemark.edges
  console.log(nodes)
  return (
    <Container>
      <Content>最新の投稿</Content>
      <RecentPostList nodes={nodes} />
    </Container>
  )
}

export default RecentPost
