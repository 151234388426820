import React from "react"
import styled from "styled-components"
import RecentPost from "../sidebar/RecentPost"

const Aside = styled.aside`
  width: 100%;
  @media (min-width: 768px) {
    width: 30%;
  }
`
const Sidebar = () => {
  return (
    <Aside>
      <RecentPost />
    </Aside>
  )
}

export default Sidebar
