import React from "react"
import Header from "./header"
import Footer from "./footer"
import Sidebar from "./sidebar"
import GlobalStyle from "./GlobalStyle"
import "prismjs/themes/prism-twilight.css"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Content = styled.div`
  padding-top: 100px;
  padding-bottom: 40px;

  @media (min-width: 768px) {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
  }
`
const Main = styled.main`
  @media (min-width: 768px) {
    width: 68%;
    padding-right: 20px;
  }
`
const LayoutSidebar = props => {
  return (
    <Container>
      <GlobalStyle />
      <Header />
      <Content>
        <Main>{props.children}</Main>
        <Sidebar />
      </Content>
      <Footer />
    </Container>
  )
}

export default LayoutSidebar
